$(function() {

	$input = $('*[data-behavior="autocomplete"]');
  $homeSearch = $('.home-search-input');

  var createOptions = function($selector) {
    return {
      getValue: "word",
      cssClasses: 'col',
      requestDelay: 250,
      url: function(phrase) {
        return "/searches/autosuggest?q=" + phrase;
      },
      list: {
        onChooseEvent: function() {
          var searchLink = $selector.getSelectedItemData().link;
          window.location = searchLink;
        }
      }
    };
  };

  $input.easyAutocomplete(createOptions($input));
  if ($homeSearch.length) {
    $homeSearch.easyAutocomplete(createOptions($homeSearch));
  }
})
