import Choices from 'choices.js';

$(document).ready(function() {
  var productId = document.getElementById('choices-single-remote-fetch').dataset.productId;
  var redirectUri = document.getElementById('choices-single-remote-fetch').dataset.redirectUri;

  var singleFetch = new Choices('#choices-single-remote-fetch', {
    allowHTML: false,
    searchPlaceholderValue: 'Search or select an option',
    classNames: {
      flippedState: ''
    }
  })
  .setChoices(function() {
    return fetch('/products/' + productId + '/variant_options?redirect_uri=' + redirectUri)
      .then(function(response) {
        return response.json();
      })
      .then(function(data) {
        return data.map(function(variant) {
          return {
            label: variant.text,
            value: variant.value,
            selected: variant.selected
          };
        });
      });
  });

  document.getElementById('choices-single-remote-fetch').addEventListener('change', function() {
    window.location.href = this.value;
  });
});
