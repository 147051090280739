$(function(){
  $(document).on('show.bs.collapse', function(e) {
    if (e.target.id === 'returnCollapse') {
      $(e.target).prev().find('.rotate-caret').css('transform', 'rotate(180deg)');
    }
  });
  
  $(document).on('hide.bs.collapse', function(e) {
    if (e.target.id === 'returnCollapse') {
      $(e.target).prev().find('.rotate-caret').css('transform', 'rotate(0deg)');
    }
  });
})
