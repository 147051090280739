import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'fieldbilling', 'citybilling', 'zipbilling', 'fieldshipping', 'cityshipping', 'zipshipping' ]

  connect() {
  	if(typeof(google) != 'undefined') {
  		this.initMap()
  	}
  }

  initMap() {
  	this.autocomplete = new google.maps.places.Autocomplete(this.fieldbillingTarget)
  	this.autocomplete.setFields(['address_components', 'geometry'])
  	this.autocomplete.setTypes(['address'])
  	this.autocomplete.setComponentRestrictions({country: ['us']})
  	this.autocomplete.addListener('place_changed', this.billingChanged.bind(this))

  	this.autocomplete2 = new google.maps.places.Autocomplete(this.fieldshippingTarget)
  	this.autocomplete2.setFields(['address_components', 'geometry'])
  	this.autocomplete2.setTypes(['address'])
  	this.autocomplete2.setComponentRestrictions({country: ['us']})
  	this.autocomplete2.addListener('place_changed', this.shippingChanged.bind(this))

    // turn off autocomplete for Chrome
    let isChrome = navigator.userAgent.includes("Chrome") && navigator.vendor.includes("Google Inc");
    let checkoutForm = document.getElementById('checkout_form_address')

    if (isChrome && checkoutForm) {
      checkoutForm.querySelectorAll('input').forEach(input => {
        // only address1 input gets the autocomplete attribute mutated by JS
        if (input.id === 'address1billing' || input.id === 'address1shipping') {
          const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
              // Check that a mutation happened on the autocomplete attribute
              if (mutation.type === "attributes" && mutation.attributeName === "autocomplete") {
                // Disconnect the observer to prevent an infinite loop (assuming EasyAutocomplete will not update the attribute in the future)
                observer.disconnect();
                mutation.target.setAttribute("autocomplete", "chrome-off")
              }
            });
          });

          // Observe the input element
          observer.observe(input, {
            attributes: true // configure it to listen to attribute changes
          });
        } else {
          input.setAttribute("autocomplete", "chrome-off");
        }
      })
    }
  }

  billingChanged() {
  	let place = this.autocomplete.getPlace()

  	let address1 = "";
  	let postcode = "";
  	let state = "";

  	for (const component of place.address_components) {
	    // @ts-ignore remove once typings fixed
	    const componentType = component.types[0];

	    switch (componentType) {
	      case "street_number": {
	        address1 = `${component.long_name} ${address1}`;
	        break;
	      }

	      case "route": {
	        address1 += component.short_name;
	        break;
	      }

	      case "postal_code": {
	        postcode = `${component.long_name}${postcode}`;
	        break;
	      }

	      case "locality":
	        this.citybillingTarget.value = component.long_name;
	        break;

	      case "administrative_area_level_1": {
	        state = component.long_name;
	        break;
	      }
	    }
	  }

	  // remove current selected
	  $('#stateSelectbilling option').filter('[selected="selected"]').removeAttr('selected')

	  // find option where state matches and select
	  $('#stateSelectbilling option').filter(":contains(" + state + ")").attr('selected', true)

  	this.fieldbillingTarget.value = address1;
  	this.zipbillingTarget.value = postcode;

  	$('#billingForm [data-places-target]').addClass('active');
  }

  shippingChanged() {
  	let place = this.autocomplete2.getPlace()

  	let address1 = "";
  	let postcode = "";
  	let state = "";

  	for (const component of place.address_components) {
	    // @ts-ignore remove once typings fixed
	    const componentType = component.types[0];

	    switch (componentType) {
	      case "street_number": {
	        address1 = `${component.long_name} ${address1}`;
	        break;
	      }

	      case "route": {
	        address1 += component.short_name;
	        break;
	      }

	      case "postal_code": {
	        postcode = `${component.long_name}${postcode}`;
	        break;
	      }

	      case "locality":
	        this.cityshippingTarget.value = component.long_name;
	        break;

	      case "administrative_area_level_1": {
	        state = component.long_name;
	        break;
	      }
	    }
	  }

	  // remove current selected
	  $('#stateSelectshipping option').filter('[selected="selected"]').removeAttr('selected')

	  // find option where state matches and select
	  $('#stateSelectshipping option').filter(":contains(" + state + ")").attr('selected', true)

  	this.fieldshippingTarget.value = address1;
  	this.zipshippingTarget.value = postcode;

  	$('#shippingForm [data-places-target]').addClass('active');
  }

  keydown(event) {
  	if(event.key == 'Enter'){
  		event.preventDefault()
  	}
  }
}
