window.dataLayer = window.dataLayer || [];
window.gtag = function() {dataLayer.push(arguments);};
gtag('js', new Date());

$(document).ready(function() {
  if (typeof gtag != 'undefined') {
    gtag('config', 'G-W0YN2J6RC0');
    gtag('config', 'AW-10776449678');
  }
})
